import React from "react";
import { Routes as Router, Route } from "react-router-dom";

import Home from "../screens/Home";
import Services from "../screens/Services";
import Gallery from "../screens/Gallery";
import Contact from "../screens/Contact";

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<Home />} />
      <Route path="services" element={<Services />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="contact" element={<Contact />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Router>
  );
};

export default Routes;
