import React, { useEffect } from "react";
import $ from "jquery";

import profilePic from "../assets/img/profilepic-background.webp";
import Logo from "../assets/img/logo.png";
import Nav from "../components/Nav";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    $(".highlight").hover(
      function () {
        $(this).height("250px");
      },
      function () {
        $(this).height("125px");
      }
    );
  });
  return (
    <>
      <div className="header">
        <div className="logo">
          <img id="logo" src={Logo} alt="logo" />
        </div>
      </div>
      <Nav />

      <div className="content container arapey mx-auto">
        <div className="row mx-0 banner pt-3">
          <img className="setup-img" src={profilePic} alt="logo for brand" />
        </div>
        <div className="row px-sm-5 px-3 pb-5">
          <div className="row py-md-5 pb-5 pt-4">
            <div className="col-md-6 px-4">
              <p>
                Hi! My name is Nicolle otherwise known as BrowBoss, I am an Eyebrow Artist with 16
                years of experience in the Beauty industry.
              </p>
              <p>
                You can find me in The BrowBoss Studio in Bilston, where I have specifically
                converted my garage to provide the best working environment for the treatments I
                provide.
              </p>
              <p>
                I have always been passionate about eyebrows and I find the most rewarding aspect of
                my job is making clients feel amazing and confident within themselves. I pride
                myself on my extensive attention to detail while making the experience of getting
                your eyebrows done joyful and stress free.
              </p>
            </div>
            <div className="col-md-6 px-4">
              <p>
                I hold various certificates including
                <ul className="certificates">
                  <li>HD Brow Master</li>
                  <li>HD Browsculpt</li>
                  <li>Henna Brows International</li>
                  <li>SVQ2 in Beauty Therapy</li>
                  <li>Threading</li>
                  <li>Phibrows Microblading Artist</li>
                  <li>Dermace Digital artist</li>
                  <li>Advanced training with Christine Alison</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row px-sm-5 px-3 pb-5 pmu justify-content-center">
            <p>
              For PMU information please see <a href="/services#information">here</a>
            </p>
          </div>
          <div className="highlight-container">
            <Link className="full-width" to="/services#microblading">
              <div className="row full-width services highlight secondary-color-text no-padding">
                <div className="highlight-text">
                  <h2>Services</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="highlight-container">
            <Link className="full-width" to="/gallery">
              <div className="row full-width gallery highlight secondary-color-text no-padding">
                <div className="highlight-text">
                  <h2>Gallery</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="highlight-container">
            <Link className="full-width" to="/contact">
              <div className="row full-width contact highlight secondary-color-text no-padding">
                <div className="highlight-text">
                  <h2>Contact</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row pb-4 justify-content-center">
          <p className="text-center">
            I look forward to meeting you!
            <br />
            BrowBoss
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
