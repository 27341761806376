import React, { useEffect } from "react";
import $ from "jquery";

import Logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";

const Nav = () => {
  useEffect(() => {
    $(".dropdown").on("show.bs.dropdown", function () {
      $(this).find(".dropdown-menu").first().stop(true, true).slideDown();
    });

    // Add slideUp animation to Bootstrap dropdown when collapsing.
    $(".dropdown").on("hide.bs.dropdown", function () {
      $(this).find(".dropdown-menu").first().stop(true, true).slideUp();
    });

    return () => {
      $(".dropdown").off("bs.dropdow", "**");
    };
  }, []);
  return (
    <div className="nav primary-color mx-auto">
      <nav className=" full-width navbar navbar-expand-lg navbar-dark arapey">
        <Link className="logo-nav" to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler collapsed float-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </button>
        <div className="px-3 collapse navbar-collapse float-right" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link secondary-color-text" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle secondary-color-text"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </button>
              <div className="dropdown-menu primary-color" aria-labelledby="navbarDropdownMenuLink">
                <Link
                  className="dropdown-item secondary-color-text"
                  data-service="#information"
                  to="/services#information"
                >
                  PMU Information
                </Link>
                <Link
                  className="dropdown-item secondary-color-text"
                  data-service="#microblading"
                  to="/services#microblading"
                >
                  Microblading
                </Link>
                <Link
                  className="dropdown-item secondary-color-text"
                  data-service="#digital"
                  to="/services#digital"
                >
                  Digital/Machine
                </Link>
                <Link
                  className="dropdown-item secondary-color-text"
                  data-service="#treatments"
                  to="/services#treatments"
                >
                  Brow Treatments
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link secondary-color-text" to="/gallery">
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link secondary-color-text" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <hr className="crop-width my-1 mx-3" />
    </div>
  );
};

export default Nav;
