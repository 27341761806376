import React, { useEffect } from "react";
import $ from "jquery";

import Nav from "../components/Nav";

import {
  microblading2,
  microblading1,
  microblading3,
  hairstroke2,
  hairstroke1,
  hairstroke3,
  ombre1,
  ombre2,
  ombre3,
  powder2,
  powder1,
  powder3,
  combination3,
  combination1,
  combination2,
  hdbrows1,
  hdbrows2,
  hdbrows3,
  henna1,
  henna2,
  browlam1,
  browlam2,
  browlam3,
  waxtint7,
  waxtint8,
  waxtint9,
} from "../assets/img/index";

const Services = () => {
  const changePanel = () => {
    var service = window.location.hash;
    $(service + "-panel").addClass("show");
    $(service + "-panel").addClass("active");
    $(service + "-tab").addClass("active");
  };

  const triggerChange = (service) => {
    $(service + "-tab").trigger("click");
  };

  useEffect(() => {
    changePanel();

    $(".dropdown-item").click(function () {
      triggerChange(this.dataset.service);
    });

    $(".client-img").click(function () {
      var modal = $(".modal");
      var modalImg = $(".modal-img");
      modal.fadeIn(200);
      modalImg.attr("src", this.src);
    });

    $(".modal, .close").click(function (e) {
      if (e.target === this) {
        $(".modal").fadeOut(200);
      }
    });
  });
  return (
    <>
      <div className="header-sm">
        <div className="logo-sm container d-flex justify-content-center align-items-center">
          <div className="row">
            <h1 id="logo" className="mx-auto header-sub-sm arapey xxlarge secondary-color-text">
              Services
            </h1>
          </div>
        </div>
      </div>
      <div>
        <Nav />
        <div className="content arapey justify mx-auto">
          <div className="row py-3 justify-content-center">
            <a className="text-color" href="https://bookings.gettimely.com/browboss1/bb/book">
              <i className="far fa-calendar-alt pr-3"></i>Click here to book your treatment
            </a>
          </div>
          <hr className="mb-3 mt-1 mx-4" />

          <nav className="pb-3">
            <div
              className="nav nav-tabs justify-content-center mx-auto"
              id="nav-tab"
              role="tablist"
            >
              <a
                className="secondary-color-text medium nav-item nav-link"
                id="information-tab"
                data-toggle="tab"
                href="#information-panel"
                role="tab"
                aria-controls="information-panel"
                aria-selected="false"
              >
                PMU Information
              </a>
              <a
                className="secondary-color-text medium nav-item nav-link"
                id="microblading-tab"
                data-toggle="tab"
                href="#microblading-panel"
                role="tab"
                aria-controls="microblading-panel"
                aria-selected="true"
              >
                Microblading
              </a>
              <a
                className="secondary-color-text medium nav-item nav-link"
                id="digital-tab"
                data-toggle="tab"
                href="#digital-panel"
                role="tab"
                aria-controls="digital-panel"
                aria-selected="false"
              >
                Digital/Machine
              </a>
              <a
                className="secondary-color-text medium nav-item nav-link"
                id="treatments-tab"
                data-toggle="tab"
                href="#treatments-panel"
                role="tab"
                aria-controls="treatments-panel"
                aria-selected="false"
              >
                Brow Treatments
              </a>
            </div>
          </nav>

          <div className="inner-content tab-content px-5" id="nav-tabContent">
            <div
              className="row px-5 pb-3 tab-pane fade"
              id="microblading-panel"
              role="tabpanel"
              aria-labelledby="microblading-tab"
            >
              <h2 className="mx-auto">Microblading</h2>
              <div>
                <p>
                  Microblading is the 'newest' trend that's hit the market over the last few years.
                  It is carried out using a hand held tool to create fine hair strokes throughout
                  the brow. It is one of the more natural looks for eyebrows but unfortunately it
                  isn't suited to everyone. It won't last well on oily skin as and is also not good
                  on thinner skin. It can last anything from 6-18 months before needing a top up
                  depending on the skin type. Digital shading can be added to this to create a
                  slightly fuller look as I don't do manual shading with this.
                </p>
              </div>
              <div class="row">
                <div class="col-4 px-md-3 px-0">
                  <img class="client-img" src={microblading1} alt="microblading1" />
                </div>
                <div class="col-4 px-md-3 px-0">
                  <img class="client-img" src={microblading2} alt="microblading2" />
                </div>
                <div class="col-4 px-md-3 px-0">
                  <img class="client-img" src={microblading3} alt="microblading3" />
                </div>
              </div>
              <div className="mt-3">
                <p>
                  Cost: £310
                  <br />
                  Annual top ups from £140.
                </p>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="digital-panel"
              role="tabpanel"
              aria-labelledby="digital-tab"
            >
              <div className="row px-5">
                <h2 className="mx-auto">Digital/Machine</h2>
                <div>
                  <p>
                    These both mean the same thing. This type of treatment is carried out using a
                    tattoo machine, and can achieve various different looks. It is less traumatic to
                    the skin than microblading as the needle comes in and out of the skin several
                    times a second as oppopsed to scratching along the skin.{" "}
                  </p>
                </div>
                <div>
                  <p>
                    Cost for any machine work: £310.
                    <br />
                    Annual top ups from £160
                  </p>
                </div>
              </div>
              <div>
                <hr className="my-5 mx-4" />
                <div className="row px-5">
                  <h3 className="mx-auto">Hairstoke/hairstroke and shading</h3>
                  <div>
                    <p>
                      Using a fine needle we can create lovely hairstrokes similar to microblading.
                      You can add shading to create a slightly fuller but still natural brow. This
                      technique will last longer than microblading on most skin types and is
                      suitable for both thinner and oily skin. Will last 12-18 months before a
                      retouch is needed.
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hairstroke2} alt="hairstroke2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hairstroke1} alt="hairstroke1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hairstroke3} alt="hairstroke3" />
                    </div>
                  </div>
                </div>
                <hr className="my-5 mx-4" />
                <div className="row px-5">
                  <h3 className="mx-auto">Ombré</h3>
                  <div>
                    <p>
                      Ombré is where the front and/or top of the eyebrow is faded more than the body
                      and tail of the brow. Good for those who like a slightly fuller look and draw
                      in their brows every day. It can be as natural as you want it to be but can
                      also be a much fuller look depending on the individual and what you would
                      like.
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={ombre1} alt="ombre1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={ombre2} alt="ombre2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={ombre3} alt="ombre3" />
                    </div>
                  </div>
                </div>
                <hr className="my-5 mx-4" />
                <div className="row px-5">
                  <h3 className="mx-auto">Powder Brow</h3>
                  <div className="service-desc">
                    <p>
                      A powder brow is a much fuller brow look. Bolder all throughout the brow, good
                      for those who like a strong bold look.{" "}
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={powder1} alt="powder1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={powder2} alt="powder2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={powder3} alt="powder3" />
                    </div>
                  </div>
                </div>
                <hr className="my-5 mx-4" />
                <div className="row px-5 pb-5">
                  <h3 className="mx-auto">Combination</h3>
                  <div>
                    <p>
                      This is where a mixture of hairstrokes and an ombre/powder brow is used.
                      Usually hairstrokes at the front of the brow and sometimes up to the arch of
                      the brow and a fuller 'filled in look' at the tails. This is a good one to
                      choose for those who have sparse but strong hair that's not the correct shape
                      as it blends in better, or those who would like a slightly fuller look but
                      still using hairstrokes. If you are still unsure about what would suit you
                      best, please feel free to send me over a close up clear picture of your
                      eyebrows without makeup on them and I can tell you what I would suggest!
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={combination1} alt="combination1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={combination2} alt="combination2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={combination3} alt="combination3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="treatments-panel"
              role="tabpanel"
              aria-labelledby="treatments-tab"
            >
              <div className="row px-5">
                <h2 className="mx-auto">Brow Treatments</h2>
              </div>
              <div>
                <hr className="mb-5 mx-4" />
                <div className="row px-5 pb-5">
                  <h3 className="mx-auto">HD Brows</h3>
                  <div className="service-desc">
                    <p>
                      HD Brows is a bespoke eyebrow treatment that is totally tailored to you. It's
                      so much more than a standard eyebrow tint and wax; with custom-blended colour,
                      hair removal and make up application for brows that suit you.
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hdbrows1} alt="hdbrow1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hdbrows2} alt="hdbrow2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={hdbrows3} alt="hdbrow3" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Cost: £30
                      <br />
                      Patch test required.
                    </p>
                  </div>
                </div>
                <hr className="mb-5 mx-4" />
                <div className="row px-5 pb-5">
                  <h3 className="mx-auto">Henna</h3>
                  <div className="service-desc">
                    <p>
                      Henna is a tint that will stain your skin as well as tint the hairs for a
                      fuller look. The tint will last on the hairs for up to 6 weeks and on the skin
                      for a few days.
                    </p>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                      <img className="client-img" src={henna1} alt="Henna 1" />
                    </div>
                    <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                      <img className="client-img" src={henna2} alt="Henna 2" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Cost: £34
                      <br />
                      Patch test required.
                    </p>
                  </div>
                </div>
                <hr className="mb-5 mx-4" />
                <div className="row px-5 pb-5">
                  <h3 className="mx-auto">Brow Lamination</h3>
                  <div className="service-desc">
                    <p>
                      'Brow lamination' or 'fluffy brows' as it is more commonly known is a chemical
                      process in which we use perming solution to reposition the hairs to create a
                      fuller fluffier look. This one is my favourite!
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={browlam1} alt="browlam1" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={browlam2} alt="browlam2" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={browlam3} alt="browlam3" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Cost: £47
                      <br />
                      Patch test required.
                    </p>
                  </div>
                </div>
                <hr className="mb-5 mx-4" />
                <div className="row px-5 pb-5">
                  <h3 className="mx-auto">Wax & Tinting</h3>
                  <div className="service-desc">
                    <p>
                      A tint to pick up any hair that we can use and then a wax all around the brow
                      to get that perfect shape for you!
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={waxtint7} alt="waxtint7" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={waxtint8} alt="waxtint8" />
                    </div>
                    <div class="col-4 px-md-3 px-0">
                      <img class="client-img" src={waxtint9} alt="waxtint9" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Cost: £19 for wax & tint or £13 for wax only
                      <br />
                      Patch test required.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="information-panel"
              role="tabpanel"
              aria-labelledby="information-tab"
            >
              <div className="row px-5">
                <h2 className="mx-auto">PMU Information</h2>
              </div>
              <div className="row px-5 pb-5">
                <p>
                  Have you been thinking about semi permanent eyebrows for a while but not sure what
                  would suit you best? Hopefully this information will help you decide what is best
                  for you!
                </p>

                <p>
                  There are many different techniques used to create a semi permanent brow. Below I
                  will explain the differences of each one and what is best suited to who.
                </p>

                <p>
                  Every semi permanent appointment requires 2 appointments which is included in the
                  full price. Sometimes a third appointment may be necessary at a fee to cover
                  product costs. All appointments require a £50 non refundable deposit to secure
                  your space which is deducted from the total cost. The cost can be split over the 2
                  appointments, for example £50 deposit upon booking, £150 for the first appointment
                  and the remaining amount due on your second appointment.
                </p>

                <p>
                  The first appointment is approximately 2.5 hours and a chunk of this time is
                  mapping the brows. I use a black pencil to create a very bold eyebrow, it's a bit
                  scary to see but this ensures we have them as symmetrical as possible and lets you
                  see what shape they will be. After this part we chat about colour, I usually
                  recommend a similar colour to your natural brow hair but of course slightly darker
                  is also an option. Once we are both happy with the choices I start the tattooing
                  process. The second appointment is between 4&8 weeks later and is 1.5 hours long.
                  We go over any faded areas and add any bits in that we need to. This is the
                  perfecting appointment, it's just as important as the first one.
                </p>

                <p>
                  A patch test is always necessary. This has to be carried out at least 48 hours
                  before any appointment and can be booked online.
                </p>

                <p>
                  Please note that semi permanent makeup can last in the skin for a number of years.
                  As time goes on it will fade requiring annual top up appointments, and regular
                  maintenance of the brows is a must. A simple wax and tint has the brows looking
                  like new again. No hair in the area where the tattoo is will be removed but your
                  hair will still grow around it and the hair will remain the same colour it always
                  has been so it's important to keep on top of this if you want your brows looking
                  their best. You can also leave the pigment to fade out if you decided you didn't
                  want anymore top ups.
                </p>

                <p>
                  The one question I am always asked is 'is it sore?' and I can honestly say I
                  haven't yet had anyone ask me to stop mid treatment! Yes it can be slightly
                  uncomfortable, it is a tattoo on your face, but we work at a slow and steady pace
                  to make this as comfortable as possible for you. A numbing cream is used after the
                  first 'pass' (the first phase on the skin) and this takes any pain away. I prefer
                  not to pre numb as this can slightly swell the skin and affect the over all look
                  of the treatment. I have had people fall asleep while doing it so that is a
                  reassuring sign that it does not hurt!
                </p>

                <p>
                  As always there is some contraindications that would prevent you from having the
                  treatment done. These are as follows.
                </p>

                <ul>
                  <li>You are under the age of 18</li>
                  <li>You are pregnant or breastfeeding</li>
                  <li>You are taking blood thinning medication</li>
                  <li>You have haemophilia</li>
                  <li>You have a healing disorder or uncontrolled high blood pressure</li>
                  <li>You take any skin medications such as accutane or steroids</li>
                  <li>You have active skin cancer</li>
                  <li>You are undergoing radiotherapy or chemotherapy</li>
                  <li>You have a pacemaker or major heart problems</li>
                  <li>You have had Botox in the last month</li>
                  <li>You are diabetic (unless well controlled and doctor can provide a letter)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal">
        <span className="close">&times;</span>
        <img className="modal-img" alt="modal container" />
      </div>
    </>
  );
};

export default Services;
