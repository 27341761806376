import React from "react";
import Nav from "../components/Nav";

const Contact = () => {
  return (
    <>
      <div className="header-sm">
        <div className="logo-sm container d-flex justify-content-center align-items-center">
          <div className="row">
            <h1 id="logo" className="mx-auto header-sub-sm arapey xxlarge secondary-color-text">
              Contact
            </h1>
          </div>
        </div>
      </div>
      <Nav />
      <div>
        <div className="content arapey justify mx-auto">
          <div className="row py-3 justify-content-center">
            <a className="text-color" href="https://bookings.gettimely.com/browboss1/bb/book">
              <i className="far fa-calendar-alt pr-3"></i>Click here to book your treatment
            </a>
          </div>
          <hr className="mb-3 mt-1 mx-4" />
          <div className="inner-content container px-5">
            <div className="row pb-3 pt-3">
              <div className="col-md-4 pb-md-3 pb-5 text-center mx-auto medium arapey contact-cont">
                <h3>Find me at:</h3>
                <a
                  href="https://goo.gl/maps/ic7ic8eZuvZPQZAa7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <div>
                      <i className="fas fa-map-marked-alt"></i>
                    </div>
                    <div className="px-3">
                      21 Corby Craig Avenue
                      <br />
                      EH25 9TL, Midlothian
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 pb-md-3 pb-5 text-center mx-auto medium arapey contact-cont">
                <h3>Contact me at:</h3>
                <a href="tel:+447732118266">
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <div>
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="px-3">07732118266</div>
                  </div>
                </a>
                <a href="mailto:browbossedinburgh@hotmail.com">
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <div>
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="px-3">browbossedinburgh@hotmail.com</div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 pb-md-3 pb-5 text-center mx-auto medium arapey follow">
                <h3>Follow me at:</h3>
                <a
                  className="insta"
                  href="https://www.instagram.com/browboss.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <div>
                      <i className="fab fa-instagram-square"></i>
                    </div>
                    <div className="px-3">@browboss.uk</div>
                  </div>
                </a>
                <a
                  className="facebook"
                  href="https://www.facebook.com/BrowBossEdinburgh/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <div>
                      <i className="fab fa-facebook-square"></i>
                    </div>
                    <div className="px-3">@BrowBoss</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.431829178308!2d-3.1846311549695034!3d55.87252359021903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887c0a2a69d49f9%3A0x36bc0bc3c012f0c7!2sCorby%20Craig%20Ave%2C%20Bilston%2C%20Loanhead%20EH25%209TL!5e0!3m2!1sen!2suk!4v1601488226547!5m2!1sen!2suk"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
