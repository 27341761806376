import React from "react";
import Logo from "../assets/img/logo.png";

const Footer = () => {
  return (
    <div className="container auto-height py-3 mx-auto footer" id="footer">
      <div className="row secondary-color-text no-padding full-width d-flex justify-content-center align-items-center">
        <div className="col-4 mx-auto text-center">
          <img className="logo-footer mx-auto" src={Logo} alt="logo" />
        </div>
        <div className="col-4 py-2 mx-auto text-center">
          <a
            href="https://www.facebook.com/BrowBossEdinburgh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-square xlarge footer-facebook primary-color-text"></i>
          </a>
          <a
            className="mx-3"
            href="https://www.instagram.com/browboss.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram-square xlarge footer-insta primary-color-text"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
