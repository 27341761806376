export const logo = require("./logo.png");

//browsculpt
export const browsculpt1 = require("./clients/browsculpt/browsculpt1.jpg");
export const browsculpt2 = require("./clients/browsculpt/browsculpt2.jpg");
export const browsculpt3 = require("./clients/browsculpt/browsculpt3.jpg");
export const browsculpt4 = require("./clients/browsculpt/browsculpt4.jpg");
// export const browsculpt5 = require("./clients/browsculpt/browsculpt5.jpg");
// export const browsculpt6 = require("./clients/browsculpt/browsculpt6.jpg");
// export const browsculpt7 = require("./clients/browsculpt/browsculpt7.jpg");

//browlam
export const browlam1 = require("./browlam/browlam1.jpeg");
export const browlam2 = require("./browlam/browlam2.jpeg");
export const browlam3 = require("./browlam/browlam3.jpeg");

//hdbrows
export const hdbrows1 = require("./clients/hdbrows/hdbrow1.jpg");
export const hdbrows2 = require("./clients/hdbrows/hdbrow2.jpg");
export const hdbrows3 = require("./clients/hdbrows/hdbrow3.jpg");

//henna
export const henna1 = require("./clients/henna/henna1.jpg");
export const henna2 = require("./clients/henna/henna2.jpg");

//recent
export const recent1 = require("./clients/recent/recent1.jpg");
export const recent2 = require("./clients/recent/recent2.jpg");
export const recent3 = require("./clients/recent/recent3.jpg");
export const recent4 = require("./clients/recent/recent4.jpg");
export const recent5 = require("./clients/recent/recent5.jpg");
export const recent6 = require("./clients/recent/recent6.jpg");
export const recent7 = require("./clients/recent/recent7.jpg");
export const recent8 = require("./clients/recent/recent8.jpg");
export const recent9 = require("./clients/recent/recent9.jpg");

//waxtint
export const waxtint1 = require("./clients/waxtint/waxtint1.jpg");
export const waxtint2 = require("./clients/waxtint/waxtint2.jpg");
export const waxtint3 = require("./clients/waxtint/waxtint3.jpg");
export const waxtint4 = require("./clients/waxtint/waxtint4.jpg");
export const waxtint5 = require("./clients/waxtint/waxtint5.jpg");
export const waxtint6 = require("./clients/waxtint/waxtint6.jpg");
export const waxtint7 = require("./clients/waxtint/waxtint7.jpg");
export const waxtint8 = require("./clients/waxtint/waxtint8.jpg");
export const waxtint9 = require("./clients/waxtint/waxtint9.jpg");

//combination
// export const combination1 = require("./clients/combination1.jpeg");
// export const combination2 = require("./clients/combination2.jpeg");
// export const combination3 = require("./clients/combination3.jpeg");
export const combination1 = require("./combination/combination1.jpeg");
export const combination2 = require("./combination/combination2.jpeg");
export const combination3 = require("./combination/combination3.jpeg");

//hairstroke
export const hairstroke1 = require("./clients/hairstroke1.jpeg");
export const hairstroke2 = require("./clients/hairstroke2.jpeg");
export const hairstroke3 = require("./clients/hairstroke3.jpeg");

//microblading
// export const microblading1 = require("./clients/microblading1.jpeg");
// export const microblading2 = require("./clients/microblading2.jpeg");
// export const microblading3 = require("./clients/microblading3.jpeg");
export const microblading1 = require("./microblading/microblading1.jpeg");
export const microblading2 = require("./microblading/microblading2.jpeg");
export const microblading3 = require("./microblading/microblading3.jpeg");

//ombre
export const ombre1 = require("./clients/ombre1.jpeg");
// export const ombre2 = require("./clients/ombre2.jpeg");
export const ombre2 = require("./ombre/ombre2.jpeg");
export const ombre3 = require("./clients/ombre3.jpeg");

//powder
// export const powder1 = require("./clients/powder1.jpeg");
// export const powder2 = require("./clients/powder2.jpeg");
// export const powder3 = require("./clients/powder3.jpeg");
export const powder1 = require("./powder-brow/powderbrow1.jpeg");
export const powder2 = require("./powder-brow/powderbrow2.jpeg");
export const powder3 = require("./powder-brow/powderbrow3.jpeg");
