import React, { useEffect } from "react";
import $ from "jquery";

import Nav from "../components/Nav";

import {
  browsculpt1,
  recent1,
  browsculpt4,
  recent4,
  recent5,
  recent6,
  recent7,
  recent2,
  recent8,
  recent9,
  recent3,
  browsculpt2,
  browsculpt3,
  waxtint1,
  waxtint2,
  waxtint3,
  waxtint4,
  waxtint5,
  waxtint6,
  combination1,
  combination2,
  combination3,
  hairstroke1,
  hairstroke2,
} from "../assets/img/index";

const Treatments = () => {
  useEffect(() => {
    var lazyloadImages = document.querySelectorAll(".lazy");
    var lazyloadThrottleTimeout;

    function lazyload() {
      console.log("lazy loading");
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (el) {
          if (el.parentElement.getBoundingClientRect().top < window.innerHeight + scrollTop) {
            el.src = el.dataset.src;
            $(el).on("load", function () {
              $(this).fadeIn();
            });
            if (el.tagName === "SOURCE") {
              var video = el.parentElement;
              video.load();
              video.play();
            }
            el.classList.remove("lazy");
            lazyloadImages = document.querySelectorAll(".lazy");
          }
        });
        if (lazyloadImages.length === 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
    lazyload();

    $(".client-img").click(function () {
      var modal = $(".modal");
      var modalImg = $(".modal-img");
      modal.fadeIn(200);
      modalImg.attr("src", this.src);
    });

    $(".modal, .close").click(function (e) {
      if (e.target === this) {
        $(".modal").fadeOut(200);
      }
    });
  });

  return (
    <>
      <div className="header-sm">
        <div className="logo-sm container d-flex justify-content-center align-items-center">
          <div className="row">
            <h1 id="logo" className="mx-auto header-sub-sm arapey xxlarge secondary-color-text">
              Gallery
            </h1>
          </div>
        </div>
      </div>
      <div>
        <Nav />
        <div className="content arapey justify mx-auto">
          <div className="inner-content container px-5">
            <div className="row">
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={recent1} alt="recent 1" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={browsculpt4} alt="browscult 4" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={browsculpt1} alt="browsculpt 1" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={recent4} alt="recent 4" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={recent5} alt="recent 5" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1">
                <img className="client-img" src={recent6} alt="recent 6" />
              </div>

              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={recent7} alt="recent 7" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={recent2} alt="recent2" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={recent8} alt="recent 8" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={recent9} alt="recent 9" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={recent3} alt="recent 3" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={browsculpt2} alt="browsculpt2" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={browsculpt3} alt="browsculpt3" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint1} alt="waxtint1" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint2} alt="waxtint2" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint3} alt="waxtint3" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint4} alt="waxtint4" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint5} alt="waxtint5" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={waxtint6} alt="waxtint6" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={combination1} alt="combination1" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={combination2} alt="combination2" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={combination3} alt="combination3" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={hairstroke1} alt="hairstroke1" />
              </div>
              <div className="col-md-4 col-6 px-md-3 px-1 py-md-3 py-1 lazy-parent">
                <img className="client-img lazy" data-src={hairstroke2} alt="hairstroke2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal">
        <span class="close">&times;</span>
        <img class="modal-img" alt="modal" />
      </div>
    </>
  );
};

export default Treatments;
